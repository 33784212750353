@tailwind base;
@layer base {
  h1 {
    @apply text-2xl font-bold tracking-tight leading-tight text-gray-800 dark:text-gray-100;
  }
  h2 {
    @apply text-xl font-bold tracking-tight leading-tight text-blue-700 dark:text-blue-500;
  }
  h3 {
    @apply text-base font-medium tracking-tight leading-tight text-gray-800 dark:text-gray-100;
  }
  h4 {
    @apply text-sm font-medium tracking-tight leading-tight text-blue-700 dark:text-blue-500;
  }
  h5 {
    @apply text-xs font-bold tracking-tight leading-tight text-gray-800 dark:text-gray-100;
  }
}

@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'GoogleSans';
  src: url('/assets/fonts/GoogleSans-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GoogleSans';
  src: url('/assets/fonts/GoogleSans-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GoogleSans';
  src: url('/assets/fonts/GoogleSans-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GoogleSans';
  src: url('/assets/fonts/GoogleSans-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GoogleSans';
  src: url('/assets/fonts/GoogleSans-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'GoogleSans';
  src: url('/assets/fonts/GoogleSans-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'GoogleSans';
  src: url('/assets/fonts/GoogleSans-BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

ins.adsbygoogle[data-ad-status='unfilled'] {
  display: none !important;
}